:root {
  --red-color: #9c332d;
  --blue-color: #3d6b92;
  --neutral-color: #B2B6AD;
  --black-color: #222222;
  --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.1);
  --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0 8px 16px rgba(0, 0, 0, 0.1);
  --font-primary: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif;
}

.App {
  text-align: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #f5f7fa 0%, #e4e8eb 100%);
  font-family: var(--font-primary);
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
}

/* Apply a global font size and consistent styling */
html,
body {
  font-size: 1rem;
  font-family: var(--font-primary);
  margin: 0;
  padding: 0;
}

button {
  font-family: var(--font-primary);
  box-shadow: var(--shadow-md);
  font-size: 1rem;
}

.game-board {
  display: flex;
  margin: 0 auto;
  max-width: 1400px;
}

.game-container {
  width: 100%;
  padding: 20px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1.5rem;
  width: 100%;
}

.bottom-controls {
  align-items: center;
  margin-top: 12px;
  width: 100%;
  justify-content: flex-end;
}

.side-panel {
  width: min(360px, 32%);
  max-height: 100%;
  padding: 20px;
  flex-shrink: 0;
}

.game-info {
  background-color: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: var(--shadow-md);
  width: 100%;
  box-sizing: border-box;
}

.game-info {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.game-info h2 {
  font-weight: 600;
  margin: 0;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.game-header h2.red,
.game-info h2.red {
  color: var(--red-color);
}

.game-header h2.blue,
.game-info h2.blue {
  color: var(--blue-color);
}

.score-progress {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.team-progress {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timer-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 5.5px;
  background-color: white;
  border-radius: 12px;
  box-shadow: var(--shadow-md);
}

.timer-display {
  font-weight: 600;
  min-width: 70px;
  text-align: center;
  color: #2c3e50;
}

.control-icons {
  display: flex;
  gap: 12px;
}

.control-icon {
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 6px;
  cursor: pointer;
  color: #2c3e50;
  transition: all 0.15s ease;
  user-select: none;
  padding: 0;
  border: none;
  outline: none;
  box-shadow: none;
  font-size: 1.2rem;
  margin: 0;
  line-height: 0;
}

.control-icon:hover {
  transform: translateY(-1px);
  box-shadow: var(--shadow-md);
}

.end-turn-button {
  padding: 12px;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.15s ease;
}

.end-turn-button.red {
  background-color: var(--red-color);
  color: white;
}

.end-turn-button.blue {
  background-color: var(--blue-color);
  color: white;
}

.end-turn-button:hover {
  transform: translateY(-1px);
  filter: brightness(1.1);
}

.toggle-buttons {
  display: flex;
  gap: 1px;
  background: #f1f5f9;
  padding: 2px;
  border-radius: 6px;
  flex: 1;
}

.toggle-btn,
.new-game-btn {
  flex: 1;
  white-space: nowrap;
  padding: 6px 12px;
  border: none;
  background: transparent;
  color: #64748b;
  font-size: 0.85rem;
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.15s ease;
  white-space: nowrap;
}

.toggle-btn.active {
  background: white;
  color: #2c3e50;
  box-shadow: var(--shadow-sm);
}

.toggle-btn:hover:not(.active) {
  background: rgba(255, 255, 255, 0.5);
}

.new-game-btn {
  width: 100%;
  background: #eaeef1;
  color: #2c3e50;
}

.new-game-btn:hover {
  transform: translateY(-1px);
  filter: brightness(1.1);
}

.grid-item {
  position: relative;
  aspect-ratio: 1;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
  border-radius: 16px;
  transform-style: preserve-3d;
  will-change: transform;
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.card-face {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style: preserve-3d;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 16px;
  box-shadow: var(--shadow-md);
  box-sizing: border-box;
}

.card-face.front {
  transform: rotateY(0);
  z-index: 2;
}

.card-face.back {
  transform: rotateY(180deg);
  z-index: 1;
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.04);
  transform-origin: center;
}

.grid-item.just-clicked {
  animation: revealAnimation 2s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  transform-origin: center; /* Ensure consistent scaling */
}

.grid-item.just-clicked .card-inner {
  animation: revealAnimationCard 2s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.grid-item.revealed .card-inner {
  transform: rotateY(180deg);
}

/* Middle columns */
.grid-item:hover, .grid-item.touch-hover {
  transform: scale(1.5);
  z-index: 10000;
}

.grid-item:not(:hover):not(.touch-hover) {
  transition-timing-function: cubic-bezier(0.34, 0.25, 0.64, 1);
}

.grid-item.spymaster-red {
  border-color: var(--red-color);
}

.grid-item.spymaster-blue {
  border-color: var(--blue-color);
}

.grid-item.spymaster-neutral {
  border-color: var(--neutral-color);
}

.grid-item.spymaster-black {
  border-color: var(--black-color);
}

.grid-item.spymaster-red,
.grid-item.spymaster-blue,
.grid-item.spymaster-neutral,
.grid-item.spymaster-black {
  box-shadow: 0px 0px 0.3rem 0.5rem currentColor;
  background: currentColor;
}

.toggle-switch {
  position: relative;
  width: 100%;
  height: 40px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch label {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: #f0f0f0;
  border-radius: 20px;
  cursor: pointer;
  padding: 0 12px;
  transition: all 0.3s ease;
}

.toggle-button {
  position: absolute;
  content: "";
  height: 32px;
  width: 50%;
  left: 4px;
  bottom: 4px;
  background: white;
  border-radius: 16px;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: var(--shadow-sm);
}

/* Checked state */
.toggle-switch input:checked + label {
  background: #2c3e50;
}

.toggle-switch input:checked + label {
  color: white;
}

.toggle-switch input:checked + label .toggle-button {
  transform: translateX(calc(100% - 8px));
}

/* Hover effects */
.toggle-switch label:hover .toggle-button {
  box-shadow: var(--shadow-md);
}

.toggle-switch input:checked + label:hover .toggle-button {
  box-shadow: var(--shadow-md);
}

@keyframes revealAnimation {
  0%,
  90% {
    transform: scale(1) translate(0, 0);
    z-index: 1000;
  }
  100% {
    transform: scale(1) translate(0, 0);
    z-index: auto;
  }
  20%,
  80% {
    /* Divide translation by scale factor (3) to compensate for scaling */
    transform: scale(3)
      translate(
        calc(var(--card-translate-x, 0) / 3),
        calc(var(--card-translate-y, 0) / 3)
      );
    z-index: 1000;
  }
}

@keyframes revealAnimationCard {
  0% {
    transform: rotateY(0deg);
  }
  20% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(180deg);
  }

  20%, 50% ::after {
    content: var(--card-message, '');
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 16px;
    white-space: nowrap;
  }
}

@keyframes messageSlide {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.grid-item.spymaster-red {
  color: var(--red-color);
}

.grid-item.spymaster-blue {
  color: var(--blue-color);
}

.grid-item.spymaster-neutral {
  color: var(--neutral-color);
}

.grid-item.spymaster-black {
  color: var(--black-color);
}

.color-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  animation: fadeInTint 0.5s forwards;
}

@keyframes fadeInTint {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

.color-overlay.red {
  background-color: var(--red-color);
}

.color-overlay.blue {
  background-color: var(--blue-color);
}

.color-overlay.neutral {
  background-color: var(--neutral-color);
}

.color-overlay.black {
  background-color: var(--black-color);
  padding: 20px;
}

/* Mobile Styles */
@media (max-width: 768px) {
  html,
  body,
  button {
    font-size: 0.85rem;
  }

  .grid-container {
    grid-template-columns: repeat(4, 1fr);
    gap: 18px;
  }

  .turn-indicator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
  }

  .turn-indicator h2 {
    margin: 0;
  }

  .game-board {
    gap: 12px;
    flex-direction: column;
    padding: 12px;
  }

  .game-container {
    padding: 0;
  }

  .score-progress {
    padding: 0;
    gap: 8px;
  }

  .timer-box {
    padding: 4px;
    gap: 4px;
    border-radius: 8px;
  }

  .control-icons {
    gap: 4px;
  }

  .control-icon {
    width: 24px;
    height: 24px;
  }

  .end-turn-button {
    padding: 6px;
    border-radius: 8px;
  }

  .bottom-controls {
    padding: 0;
    justify-content: center;
    margin-top: 4px;
  }

  .toggle-btn {
    padding: 4px 8px;
  }

  .new-game-btn {
    padding: 4px 8px;
    margin-top: 12px;
  }

  .message-content {
    margin-top: 16px;
  }
}

@media (max-width: 480px) {
  .bottom-controls {
    flex-direction: column;
    gap: 8px;
    align-items: stretch;
  }

  .toggle-buttons {
    width: 100%;
  }

  .new-game-btn {
    width: 100%;
  }
}

.timer-display {
  font-weight: bold;
}

.control-icons {
  display: flex;
  gap: 5px;
  align-items: center;
}

.control-icon {
  cursor: pointer;
  user-select: none;
  width: 2em;
  text-align: center;
}

.copy-link {
  margin-left: 10px;
}

.role-toggle {
  display: flex;
  align-items: center;
}

.toggle-buttons {
  display: flex;
  gap: 1px;
  background: #f1f5f9;
  padding: 2px;
  border-radius: 6px;
}

.side-panel-mobile {
  padding: 12px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 12px;
}

.game-header {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.score-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.message-content {
  font-weight: 500;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-align: center;
}

.mobile-controls {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 12px;
}

.mobile-controls .toggle-buttons {
  flex: 1;
  min-width: 200px;
}

.mobile-controls .new-game-btn,
.mobile-controls .share-btn {
  padding: 8px 16px;
  white-space: nowrap;
}
